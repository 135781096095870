<template>
    <div class="container-fluid dashboard">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    <img src="https://webparke.com/atlrxinternal/wp-content/uploads/2020/10/logo.png">
                </div>
                <h3 class="text-center">Download Products</h3>
                <div class="row d-flex justify-content-center">
                    <div class="col-6">
                        <div class="shadow card">
                            <div class="card-body">
                                <div class="card-title">Search Products</div>
                                <div class="form-group">
                                    <x-tag-input :value="customFilters[0].query_1"
                                                 :resource="'product'"
                                                 :column="'name'"
                                                 :name="'name'"
                                                 @input="value => {selectQuery('name', value)}" multiple placeholder="Select name">
                                    </x-tag-input>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <x-tag-input :value="customFilters[1].query_1"
                                                         :resource="'product'"
                                                         :column="'strain'"
                                                         :name="'strain'"
                                                         @input="value => {selectQuery('strain', value)}" multiple placeholder="Select strain">
                                            </x-tag-input>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <x-tag-input :value="customFilters[2].query_1"
                                                         :resource="'product'"
                                                         :column="'batch'"
                                                         :name="'batch'"
                                                         @input="value => {selectQuery('batch', value)}" multiple placeholder="Select batch">
                                            </x-tag-input>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <x-tag-input :value="customFilters[3].query_1"
                                                         :resource="'product'"
                                                         :column="'weight'"
                                                         :name="'weight'"
                                                         @input="value => {selectQuery('weight', value)}" multiple placeholder="Select weight">
                                            </x-tag-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <filterable :endpoint="'products'" :params="this.getQuery()" :sortable="sortable" :filter-group="filters" ref="filterable">
                    <template slot="card-header">
                        <div></div>
                    </template>
                    <template slot="filter-nav" slot-scope="{ sortingClass, sortData }">
                    </template>
                    <template slot-scope="{ items, remove, sortingClass, sortData }" slot="content">
                        <table v-if="!_.isEmpty(items.data)" class="table table-small mb-0">
                            <thead class="thead-light">
                            <tr class="sorting">
                                <th><a :class="sortingClass('name')" href="#" @click="sortData('name')" >Name</a></th>
                                <th><a :class="sortingClass('strain')" href="#" @click="sortData('strain')" >Strain</a></th>
                                <th><a :class="sortingClass('batch')" href="#" @click="sortData('batch')" >Batch</a></th>
                                <th><a :class="sortingClass('weight')" href="#" @click="sortData('weight')" >Weight</a></th>
                                <th width="50">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in items.data" v-bind:key="item.id">
                                <td>
                                    <span class="font-weight-bold">{{_.get(item,'name','-')}}</span><br>
                                </td>
                                <td>{{_.get(item,'strain','-')}}</td>
                                <td>{{_.get(item,'batch','-')}}</td>
                                <td>{{_.get(item,'weight','-')}} <span v-if="_.has(item,'weight','-')">Gram</span></td>
                                <td class="actions">
                                    <a :href="`/product/download/${item.code}`" class="m-2"><i class="fas fa-cloud-download-alt"></i></a>
                                    <a :href="`/products/${item.code}`" class="m-2" target="_blank"><i class="fas fa-eye"></i></a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div v-if="_.isEmpty(items.data)" class="alert alert-info text-center">
                            No records found
                        </div>
                    </template>
                </filterable>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            query: {
                sort: "name",
                sort_dir: "desc",
            },
            customFilters: [
                {
                    column: "name",
                    operator: "includes",
                    query_1: []
                },
                {
                    column: "strain",
                    operator: "includes",
                    query_1: []
                },
                {
                    column: "batch",
                    operator: "includes",
                    query_1: []
                },
                {
                    column: "weight",
                    operator: "includes",
                    query_1: []
                }
            ]
        }
    },
    computed: {
        sortable() {
            return [
                {value: "name", name: "Name"},
                {value: "strain", name: "Strain"},
                {value: "batch", name: "Batch"},
                {value: "weight", name: "Weight"}
            ];
        },
        filters() {
            return [{
                title: "Products",
                filters: [
                    {name: "name", title: "Name", type: 'lookup', resource: 'product'},
                    {name: "strain", title: "Strain", type: 'lookup', resource: 'product'},
                    {name: "batch", title: "Batch", type: 'string', resource: 'product'},
                    {name: "weight", title: "Weight", type: 'numeric', resource: 'product'}
                ]
            },
            ];
        }
    },
    methods: {
        selectQuery(key, value) {
            if(key === "name")
            {
                this.customFilters[0].query_1 = value;
            }
            if(key === "strain")
            {
                this.customFilters[1].query_1 = value;
            }
            if(key === "batch")
            {
                this.customFilters[2].query_1 = value;
            }
            if(key === "weight")
            {
                this.customFilters[3].query_1 = value;
            }
        },
        getQuery() {
            const f = {};
            _.forEach(this.customFilters, (filter, i) => {
                if(filter.query_1 && !_.isEmpty(filter.query_1)) {
                    f[`f[${i}][column]`] = filter.column;
                    f[`f[${i}][operator]`] = filter.operator;
                    const list = filter.query_1.map((item) => {
                        return item.value;
                    });
                    if(Array.isArray(filter.query_1)){
                        f[`f[${i}][query_1]`] = list.join(',,');
                    }else{
                        f[`f[${i}][query_1]`] = filter.query_1;
                    }
                }

                f[`f[${i}][query_2]`] = filter.query_2;
            });
            return {
                ...this.params,
                ...this.query,
                ...f
            };
        },
    }
}
</script>

<style scoped>

</style>
