/**
 * src/api/http.js
 */
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 *
 * parse error response
 */
function parseError (messages) {
    // error
    if (messages) {
        if (messages instanceof Array) {
            return Promise.reject({ messages: messages })
        } else {
            return Promise.reject({ messages: [messages] })
        }
    } else {
        return Promise.reject({ messages: ['エラーが発生しました'] })
    }
}

/**
 * parse response
 */
function parseBody (response) {
//  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
    if (response.status === 200) {
        return response.data.result
    } else {
        return this.parseError(response.data.messages)
    }
}

/**
 * axios instance
 */
let instance = window.axios.create({
    baseURL: `/`,
    //paramsSerializer: function (params) {
        //return qs.stringify(params, { indices: false })
    //}
});

// request header
instance.interceptors.request.use((config) => {
    // Do something before request is sent

    // api tokenなどを利用してheaderに載せる場合
    // const apiToken = sessionStorage.getItem('token')
    // config.headers = { 'Custom-Header-IF-Exist': apiToken }
    return config
}, error => {
    if (error.response.status === 401 && typeof _loginPath !== "undefined") {
        if(window.location.pathname !== _loginPath){
            window.location = _loginPath;
        }
    } else {
        return Promise.reject(error);
    }
});

// response parse
/*instance.interceptors.response.use((response) => {
    return parseBody(response)
}, error => {
    console.warn('Error status', error.response.status)
    // return Promise.reject(error)
    if (error.response) {
        return parseError(error.response.data)
    } else {
        return Promise.reject(error)
    }
});*/

export default instance;
