var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid dashboard" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("h3", { staticClass: "text-center" }, [
            _vm._v("Download Products")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row d-flex justify-content-center" }, [
            _c("div", { staticClass: "col-6" }, [
              _c("div", { staticClass: "shadow card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v("Search Products")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("x-tag-input", {
                        attrs: {
                          value: _vm.customFilters[0].query_1,
                          resource: "product",
                          column: "name",
                          name: "name",
                          multiple: "",
                          placeholder: "Select name"
                        },
                        on: {
                          input: function(value) {
                            _vm.selectQuery("name", value)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("x-tag-input", {
                            attrs: {
                              value: _vm.customFilters[1].query_1,
                              resource: "product",
                              column: "strain",
                              name: "strain",
                              multiple: "",
                              placeholder: "Select strain"
                            },
                            on: {
                              input: function(value) {
                                _vm.selectQuery("strain", value)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("x-tag-input", {
                            attrs: {
                              value: _vm.customFilters[2].query_1,
                              resource: "product",
                              column: "batch",
                              name: "batch",
                              multiple: "",
                              placeholder: "Select batch"
                            },
                            on: {
                              input: function(value) {
                                _vm.selectQuery("batch", value)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("x-tag-input", {
                            attrs: {
                              value: _vm.customFilters[3].query_1,
                              resource: "product",
                              column: "weight",
                              name: "weight",
                              multiple: "",
                              placeholder: "Select weight"
                            },
                            on: {
                              input: function(value) {
                                _vm.selectQuery("weight", value)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "filterable",
            {
              ref: "filterable",
              attrs: {
                endpoint: "products",
                params: this.getQuery(),
                sortable: _vm.sortable,
                "filter-group": _vm.filters
              },
              scopedSlots: _vm._u([
                {
                  key: "filter-nav",
                  fn: function(ref) {
                    var sortingClass = ref.sortingClass
                    var sortData = ref.sortData
                    return undefined
                  }
                },
                {
                  key: "content",
                  fn: function(ref) {
                    var items = ref.items
                    var remove = ref.remove
                    var sortingClass = ref.sortingClass
                    var sortData = ref.sortData
                    return [
                      !_vm._.isEmpty(items.data)
                        ? _c(
                            "table",
                            { staticClass: "table table-small mb-0" },
                            [
                              _c("thead", { staticClass: "thead-light" }, [
                                _c("tr", { staticClass: "sorting" }, [
                                  _c("th", [
                                    _c(
                                      "a",
                                      {
                                        class: sortingClass("name"),
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return sortData("name")
                                          }
                                        }
                                      },
                                      [_vm._v("Name")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _c(
                                      "a",
                                      {
                                        class: sortingClass("strain"),
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return sortData("strain")
                                          }
                                        }
                                      },
                                      [_vm._v("Strain")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _c(
                                      "a",
                                      {
                                        class: sortingClass("batch"),
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return sortData("batch")
                                          }
                                        }
                                      },
                                      [_vm._v("Batch")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _c(
                                      "a",
                                      {
                                        class: sortingClass("weight"),
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            return sortData("weight")
                                          }
                                        }
                                      },
                                      [_vm._v("Weight")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { width: "50" } }, [
                                    _vm._v("Action")
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(items.data, function(item) {
                                  return _c("tr", { key: item.id }, [
                                    _c("td", [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm._.get(item, "name", "-"))
                                          )
                                        ]
                                      ),
                                      _c("br")
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm._.get(item, "strain", "-"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm._.get(item, "batch", "-"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm._.get(item, "weight", "-")) +
                                          " "
                                      ),
                                      _vm._.has(item, "weight", "-")
                                        ? _c("span", [_vm._v("Gram")])
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "actions" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "m-2",
                                          attrs: {
                                            href:
                                              "/product/download/" + item.code
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-cloud-download-alt"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "m-2",
                                          attrs: {
                                            href: "/products/" + item.code,
                                            target: "_blank"
                                          }
                                        },
                                        [_c("i", { staticClass: "fas fa-eye" })]
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._.isEmpty(items.data)
                        ? _c(
                            "div",
                            { staticClass: "alert alert-info text-center" },
                            [
                              _vm._v(
                                "\n                        No records found\n                    "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            },
            [_c("template", { slot: "card-header" }, [_c("div")])],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("img", {
        attrs: {
          src:
            "https://webparke.com/atlrxinternal/wp-content/uploads/2020/10/logo.png"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }