import { AbilityBuilder } from '@casl/ability';

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */


function subjectName(item) {
    if (typeof item === 'string' && _.startsWith(item, 'ROLE_')) {
        return "roles";
    }else if (typeof item === 'string' && _.startsWith(item, 'DEPARTMENT_')) {
        return "departments";
    }

    if (typeof item === 'string') {
        return item;
    }

    return 'permissions'
}

export default AbilityBuilder.define({ subjectName }, can => {
    // code...
})