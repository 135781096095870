<template>
    <div></div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            user: {
                type: Object,
                default: {
                    id: {
                        type: Number,
                        default: null
                    },
                    identifier: {
                        type: String,
                        default: null
                    },
                    email: {
                        type: String,
                        default: null
                    }
                }
            },
            rules: {
                type: Object,
                default: {
                    departments: {
                        type: Array,
                        default: []
                    },
                    permissions: {
                        type: Array,
                        default: []
                    },
                    roles: {
                        type: Array,
                        default: []
                    },
                    domains: {
                        type: Array,
                        default: []
                    }
                }
            }
        },
        computed: {
            //...mapGetters('AccountRules', ['departments', 'permissions', 'roles'])
        },
        created() {
            //this.$eventHub.$on('rules-update', this.rulesUpdate);
            this.setUser();
            this.rulesUpdate();

        },
        methods: {
            setUser() {
                this.$Auth.setUser(this.user);
                this.$Auth.setDomains(this.rules.domains);
            },
            rulesUpdate() {
                /*this.$ability.update([
                    { subject: 'departments', actions: this.departments },
                    { subject: 'permissions', actions: this.permissions },
                    { subject: 'roles', actions: this.roles }
                ]);*/
                this.$ability.update([
                    { subject: 'departments', actions: this.rules.departments },
                    { subject: 'permissions', actions: this.rules.permissions },
                    { subject: 'roles', actions: this.rules.roles }
                ]);
            }
        }
    }
</script>

<style scoped>

</style>
